import React from "react"
import { navigate } from 'gatsby'
/** @jsx jsx */
import { jsx } from 'theme-ui'

import { GiHamburgerMenu } from 'react-icons/gi'
import { IoMdClose } from 'react-icons/io'

import {
  Menu,
  useMenuButtonContext,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from "@reach/menu-button"
import "@reach/menu-button/styles.css"

const Button = ({ theme, onClick }) => {
  const { isExpanded } = useMenuButtonContext()

  const handleOnClick = () => {
    // for some reason it's returning the opposite. Maybe it's the previous state.
    onClick && onClick(!isExpanded)
  }

  return (
    <MenuButton
      onKeyDown={handleOnClick}
      onMouseDown={handleOnClick}
      sx={{
        bg: `unset`,
        border: `none`,
        fontSize: 5,
        color: `text`,
        display: `flex`,
        justifyContent: `center`,
      }}
    >
      <span aria-label="Menu" sx={{ display: `flex` }}>
        {isExpanded ? (
          <IoMdClose />
        ) : (
          <GiHamburgerMenu />
        )}
      </span>
    </MenuButton>
  )
}

export const MobileNavigation = ({ menuItems, onClick }) => {
  const handleOnSelect = item => {
    if (!item) {
      return
    }

    const { internalLink, externalLink } = item

    if (internalLink) {
      navigate(internalLink?.path?.current)
    } else if (externalLink) {
      navigate(externalLink)
    } else if (relativePath) {
      if (relativePath.startsWith("#")) {
        navigate(`/${relativePath}`)
      } else {
        navigate(relativePath)
      }
    } else {
      return null
    }
  }

  return (
    <Menu>
      <Button onClick={onClick} />
      <MenuPopover
        sx={{
          top: `0 !important`,
          left: `0 !important`,
          position: `fixed !important`,
          width: `100%`,
          height: `100vh`,
          backgroundColor: `background`,
          overflowY: `hidden`,
          display: `flex`,
          alignItems: `center`,
        }}
      >
        {menuItems?.length > 0 && (
          <MenuItems
            sx={{
              backgroundColor: `background`,
              border: `none`,
              pb: 5,
              px: 3,
              overflowY: `hidden`,
              width: `100%`,
              "[data-reach-menu-item][data-selected]": {
                bg: `primary`,
              },
            }}
          >
            {menuItems.map(item => {
              return (
                <MenuItem
                  key={item._key}
                  onSelect={() => handleOnSelect(item)}
                  sx={{
                    fontSize: 7,
                    fontWeight: `bold`,
                  }}
                >
                  <span>{item.title}</span>
                </MenuItem>
              )
            })}
          </MenuItems>
        )}
      </MenuPopover>
    </Menu>
  )
}
