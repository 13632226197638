import { Link } from "gatsby"
import React, { useState } from "react"

/** @jsx jsx */
import { Container, jsx } from 'theme-ui'
import { breakpoints } from "../gatsby-plugin-theme-ui/breakpoints"

import { mediaQueries } from '../gatsby-plugin-theme-ui/media-queries'
import { MobileNavigation } from "./mobile-navigation"

export const Header = ({ header, siteName }) => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false)

  return (
    <header
      sx={{
        position: `fixed`,
        top: 0,
        left: 0,
        zIndex: 1,
        width: `100%`,
        [mediaQueries.md]: {
          position: `absolute`,
        },
      }}
    >
      <Container
        sx={{
          px: [4, 4, 5],
          py: 4,
          maxWidth: breakpoints.xxxl,
          display: `flex`,
          alignItems: `center`,
        }}
      >
        <div sx={{ flex: 1 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
              fontWeight: `bold`,
            }}
            onClick={() => setIsBurgerOpen(false)}
          >
            {siteName}
          </Link>
        </div>
        <nav
          sx={{
            display: `none`,
            alignSelf: `flex-end`,
            [mediaQueries.md]: {
              display: `block`,
            },
          }}
        >
          <ul
            sx={{
              variant: `list.plain`,
              display: `flex`,
            }}
          >
            {header?.items?.length > 0 &&
              header.items.map(item => {
                if(item.relativePath) {
                  return (
                    <li
                      key={item._key}
                      sx={{
                        fontWeight: `600`,
                        ml: 4,
                        mb: 0,
                        ".is-active": {
                          fontWeight: `bold`,
                        },
                      }}
                    >
                      <Link
                        to={item.relativePath}
                        sx={{ variant: `anchor.plain` }}
                        activeClassName="is-active"
                      >
                        {item.title}
                      </Link>
                    </li>
                  )
                }
                return (
                  <li
                    key={item._key}
                    sx={{
                      fontWeight: `600`,
                      ml: 4,
                      mb: 0,
                      ".is-active": {
                        fontWeight: `bold`,
                      },
                    }}
                  >
                    <Link
                      to={item.internalLink?.path?.current}
                      sx={{ variant: `anchor.plain` }}
                      activeClassName="is-active"
                    >
                      {item.title}
                    </Link>
                  </li>
                )
              })}
          </ul>
        </nav>
        <div
          sx={{
            [mediaQueries.md]: {
              display: `none`,
            }
          }}
        >
          <MobileNavigation
            menuItems={header?.items}
            onClick={isExpanded => setIsBurgerOpen(isExpanded)}
          />
        </div>
      </Container>
    </header>
  )
}
