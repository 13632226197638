import React from 'react'

/** @jsx jsx */
import { Container, jsx } from 'theme-ui'

export const Footer = () => {
  return (
    <footer
      sx={{
        px: 4,
        py: 4,
      }}
    >
      <Container>
        <p sx={{ m: 0, fontWeight: `bold` }}>Instinct Pump</p>
      </Container>
    </footer>
  )
}