/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import { Header } from "./header"
import { Footer } from "./footer"

/** @jsx jsx */
import { jsx, Container } from "theme-ui"

const Layout = ({ siteSettings = {}, header = {}, children }) => {
  
  const { seo } = siteSettings

  return (
    <>
      <Header header={header} siteName={siteSettings?.siteName} />
      <GatsbySeo
        title={seo?.metaTitle}
      />
      <main sx={{ minHeight: `100vh` }}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
